import React, { useEffect, Suspense } from "react";
import "../styles/contact.css"

import MailIcon from '@mui/icons-material/Mail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing"
import Cyberorb from "../Components/Cyberorb";

const Contact = () => {

  return (
    <div className="contact_page__container">
      <div className="contact__content_container">
        <div className="contact_links__container">
          <h1>LET'S GET IN TOUCH!</h1>
          <p>Want to collaborate on a project? Feel free to reach out via email or LinkedIn!</p>
          <div className="contact__buttons_container">
            <a
              href="mailto:escobarreyesarianna@gmail.com"
              target="_blank"
            >
              <MailIcon className="contact_links__icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/arianna-escobar-reyes/"
              target="_blank"
            >
              <LinkedInIcon className="contact_links__icon" />
            </a>
            <a
              href="https://github.com/Addaxe/"
              target="_blank"
            >
              <GitHubIcon className="contact_links__icon" />
            </a>
          </div>
        </div>
        <div className="contact_cyberorb__model">
          {/* <canvas id="canvas3d"></canvas> */}
          <Canvas camera={{ position: [0, 0, 5], zoom: 3 }}>
            <ambientLight color={0xF7C25C} intensity={10} />
            <Suspense fallback={null}>
              <OrbitControls enableZoom={false} />
              <Cyberorb />
            </Suspense>
            <EffectComposer>
              <Bloom
                strength={1}
                luminanceThreshold={0.2}
                luminanceSmoothing={0.2}
              />
            </EffectComposer>
          </Canvas>
        </div>
      </div>
    </div>
  );
};

export default Contact;
